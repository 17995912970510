import * as React from 'react';

function SvgVaccine(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1.5rem'
			height='1.5rem'
			viewBox='0 0 344.000000 344.000000'
			{...props}>
			<g transform='translate(0.000000,344.000000) scale(0.100000,-0.100000)' stroke='none'>
				<path d='M876 3314 c-13 -13 -16 -46 -16 -201 l0 -186 -55 -58 c-54 -56 -55 -59 -55 -116 0 -57 -1 -60 -32 -74 -17 -8 -41 -27 -52 -42 -21 -28 -21 -37 -24 -855 l-2 -827 -29 -17 c-41 -23 -63 -61 -69 -117 -9 -94 51 -163 156 -176 l52 -7 0 -105 0 -105 -47 -42 c-44 -40 -47 -45 -51 -104 -4 -54 -1 -67 21 -101 41 -62 73 -71 245 -71 138 0 151 2 183 23 102 69 107 196 10 268 l-31 23 0 106 0 106 62 12 c68 13 111 44 134 99 29 68 2 154 -60 192 l-36 23 0 827 c0 808 0 829 -20 853 -10 14 -32 31 -48 37 -27 11 -30 17 -35 78 -5 62 -7 67 -56 117 l-51 52 0 180 c0 146 -3 184 -16 202 -18 26 -56 29 -78 6z m73 -520 c13 -17 21 -41 21 -65 l0 -39 -55 0 -55 0 0 42 c0 29 7 49 22 65 28 30 42 29 67 -3z m121 -324 l0 -110 -38 0 c-45 0 -62 -14 -62 -50 0 -36 17 -50 62 -50 l38 0 0 -55 0 -55 -34 0 c-19 0 -42 -7 -50 -16 -38 -37 -4 -94 56 -94 27 0 28 -2 28 -55 l0 -55 -38 0 c-45 0 -62 -14 -62 -50 0 -36 17 -50 62 -50 l38 0 0 -55 0 -55 -34 0 c-19 0 -42 -7 -50 -16 -38 -37 -4 -94 56 -94 27 0 28 -2 28 -55 l0 -55 -38 0 c-45 0 -62 -14 -62 -50 0 -36 17 -50 62 -50 l38 0 0 -110 0 -110 -160 0 -160 0 0 700 0 700 160 0 160 0 0 -110z m0 -1450 l0 -50 -160 0 -160 0 0 50 0 50 160 0 160 0 0 -50z m94 -176 c23 -22 20 -60 -6 -78 -33 -24 -469 -24 -492 0 -22 21 -20 70 2 83 10 6 110 11 249 11 197 0 234 -2 247 -16z m-194 -309 l0 -105 -55 0 -55 0 0 105 0 105 55 0 55 0 0 -105z m89 -229 c20 -24 9 -69 -19 -83 -31 -16 -205 -17 -248 -2 -38 13 -49 42 -28 74 16 24 19 25 150 25 104 0 136 -3 145 -14z' />
				<path d='M1699 2601 l-29 -29 0 -149 c0 -166 6 -184 66 -209 33 -14 34 -17 34 -67 l0 -52 -47 -17 c-96 -34 -177 -118 -206 -212 -9 -31 -12 -219 -12 -766 0 -700 1 -727 20 -779 25 -68 92 -144 159 -179 l51 -27 451 -3 c443 -3 452 -2 510 19 79 30 153 104 183 183 21 58 21 59 19 805 l-3 746 -27 51 c-37 70 -116 139 -184 161 l-54 17 0 52 c0 51 1 53 36 64 62 21 74 54 74 208 0 229 45 212 -538 212 l-474 0 -29 -29z m931 -181 l0 -110 -430 0 -430 0 0 103 c0 57 3 107 7 110 3 4 197 7 430 7 l423 0 0 -110z m-100 -270 l0 -50 -325 0 -325 0 0 50 0 50 325 0 325 0 0 -50z m121 -175 c75 -27 139 -107 139 -175 l0 -30 -584 0 c-374 0 -587 4 -591 10 -9 15 11 78 36 115 25 36 74 72 115 85 16 4 212 9 435 9 342 1 413 -1 450 -14z m139 -870 l0 -565 -590 0 -590 0 0 558 c0 307 3 562 7 565 3 4 269 7 590 7 l583 0 0 -565z m-3 -717 c-8 -58 -52 -117 -112 -147 l-49 -26 -426 0 c-419 0 -426 0 -466 22 -65 35 -102 85 -119 161 l-7 32 593 0 592 0 -6 -42z' />
				<path d='M2131 1538 c-49 -26 -91 -90 -91 -137 0 -41 41 -113 79 -138 72 -48 187 -19 226 57 23 44 19 121 -7 160 -53 77 -131 99 -207 58z m115 -113 c20 -30 10 -63 -23 -76 -51 -19 -94 41 -58 81 25 27 61 25 81 -5z' />
				<path d='M1810 1378 c-81 -41 -111 -130 -70 -212 28 -57 63 -79 132 -84 50 -4 64 -1 97 21 51 34 75 85 69 149 -3 27 -14 62 -25 76 -23 32 -97 72 -133 72 -14 0 -46 -10 -70 -22z m101 -98 c35 -19 16 -100 -24 -100 -24 0 -57 32 -57 55 0 44 40 67 81 45z' />
				<path d='M2476 1390 c-63 -19 -106 -83 -106 -157 0 -90 63 -153 153 -153 148 0 219 166 114 268 -48 46 -100 60 -161 42z m86 -117 c26 -23 23 -59 -7 -79 -51 -33 -104 21 -71 71 19 29 51 32 78 8z' />
				<path d='M2115 1162 c-39 -18 -105 -83 -105 -103 0 -5 -24 -33 -54 -62 -65 -63 -85 -116 -68 -176 35 -115 160 -177 343 -169 87 3 104 7 165 38 88 45 128 98 128 169 0 57 -35 118 -82 146 -15 9 -39 38 -52 63 -37 68 -95 105 -173 109 -45 3 -73 -1 -102 -15z m152 -107 c12 -8 29 -35 39 -60 12 -33 27 -51 54 -66 82 -46 75 -100 -19 -147 -79 -38 -190 -38 -273 0 -95 44 -101 92 -18 152 32 23 50 43 50 57 0 11 13 34 29 50 24 23 38 29 73 29 24 0 53 -7 65 -15z' />
			</g>
		</svg>
	);
}

export default SvgVaccine;
