import { useContext } from 'react';
import ThemeContext from '../contexts/themeContext';

const useLocalStorage = () => {
	const { localAuthValue, setLocalAuthValue } = useContext(ThemeContext);
	const parseObj = localAuthValue
		? typeof localAuthValue === 'string'
			? JSON.parse(localAuthValue)
			: localAuthValue
		: null;

	return { parseObj, localAuthValue, setLocalAuthValue };
};

export default useLocalStorage;
