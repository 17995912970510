import React from 'react';
import Header from '../../../layout/Header/Header';
import CommonHeaderChat from './CommonHeaderChat';
import CommonHeaderRight from './CommonHeaderRight';

const DashboardHeader = () => {
	return (
		<Header>
			{/* <HeaderLeft>
				<Search />
			</HeaderLeft> */}
			<div className='d-flex justify-content-end'>
				<CommonHeaderRight afterChildren={<CommonHeaderChat />} />
			</div>
		</Header>
	);
};

export default DashboardHeader;
