import * as React from "react";

const SvgVans = (props) => (
  <svg
    width="1.6rem"
    height="1.6rem"
    viewBox="0 0 32 32"
    id="icon"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:none;}"}</style>
    </defs>
    <title>{"van"}</title>
    <path d="M29.81,16l-7-9.56A1,1,0,0,0,22,6H3A1,1,0,0,0,2,7V24a1,1,0,0,0,1,1H5.14a4,4,0,0,0,7.72,0h6.28a4,4,0,0,0,7.72,0H29a1,1,0,0,0,1-1V16.56A1,1,0,0,0,29.81,16ZM20,8h1.49l5.13,7H20ZM9,26a2,2,0,1,1,2-2A2,2,0,0,1,9,26Zm14,0a2,2,0,1,1,2-2A2,2,0,0,1,23,26Zm5-3H26.86a4,4,0,0,0-7.72,0H12.86a4,4,0,0,0-7.72,0H4V8H18v9H28Z" />
    <rect
      id="_Transparent_Rectangle_"
      data-name="&lt;Transparent Rectangle&gt;"
      className="cls-1"
      width={32}
      height={32}
    />
  </svg>
);

export default SvgVans;
