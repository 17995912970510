import * as React from 'react';

const SvgDailyNotes = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1.5rem'
		height='1.2rem'
		viewBox='0 0 256.000000 256.000000'
		{...props}>
		<g transform='translate(0.000000,256.000000) scale(0.100000,-0.100000)' stroke='none'>
			<path d='M92 2548 c-8 -8 -12 -45 -12 -100 l0 -88 -37 -34 c-56 -51 -56 -121 0 -172 l37 -34 0 -120 0 -120 -38 -34 c-34 -31 -37 -39 -37 -86 0 -47 3 -55 37 -86 l38 -34 0 -120 0 -120 -37 -34 c-35 -31 -38 -39 -38 -86 0 -47 3 -55 37 -86 l38 -34 0 -120 0 -120 -38 -34 c-34 -31 -37 -39 -37 -86 0 -47 3 -55 37 -86 l38 -34 0 -120 0 -120 -37 -34 c-35 -31 -38 -39 -38 -86 0 -47 3 -55 38 -86 l37 -34 0 -88 c0 -55 4 -92 12 -100 17 -17 1719 -17 1736 0 17 17 17 2519 0 2536 -17 17 -1719 17 -1736 0z m1668 -1268 l0 -1200 -800 0 -800 0 0 59 0 58 46 6 c69 7 109 51 109 117 0 66 -40 110 -109 117 l-46 6 0 117 0 117 46 6 c69 7 109 51 109 117 0 66 -40 110 -109 117 l-46 6 0 117 0 117 46 6 c69 7 109 51 109 117 0 66 -40 110 -109 117 l-46 6 0 117 0 117 46 6 c69 7 109 51 109 117 0 66 -40 110 -109 117 l-46 6 0 117 0 117 46 6 c69 7 109 51 109 117 0 66 -40 110 -109 117 l-46 6 0 58 0 59 800 0 800 0 0 -1200z m-1526 978 c20 -33 -4 -53 -68 -56 -60 -3 -86 8 -86 37 0 32 22 42 84 39 43 -2 62 -8 70 -20z m0 -480 c20 -33 -4 -53 -68 -56 -60 -3 -86 8 -86 37 0 32 22 42 84 39 43 -2 62 -8 70 -20z m0 -480 c20 -33 -4 -53 -68 -56 -60 -3 -86 8 -86 37 0 32 22 42 84 39 43 -2 62 -8 70 -20z m0 -480 c20 -33 -4 -53 -68 -56 -60 -3 -86 8 -86 37 0 32 22 42 84 39 43 -2 62 -8 70 -20z m0 -480 c20 -33 -4 -53 -68 -56 -60 -3 -86 8 -86 37 0 32 22 42 84 39 43 -2 62 -8 70 -20z' />
			<path d='M1132 2308 c-16 -16 -15 -43 2 -57 9 -8 79 -11 231 -9 195 3 219 5 229 21 8 12 8 22 0 35 -10 15 -34 17 -230 20 -162 2 -223 -1 -232 -10z' />
			<path d='M732 1828 c-16 -16 -15 -43 2 -57 9 -8 131 -10 431 -9 385 3 419 4 429 21 8 12 8 22 0 35 -10 16 -44 17 -430 20 -318 2 -423 -1 -432 -10z' />
			<path d='M492 1588 c-16 -16 -15 -43 2 -57 9 -8 162 -10 551 -9 500 3 539 4 549 21 8 12 8 22 0 35 -10 16 -49 17 -551 20 -411 2 -542 -1 -551 -10z' />
			<path d='M492 1348 c-16 -16 -15 -43 2 -57 9 -8 162 -10 551 -9 500 3 539 4 549 21 8 12 8 22 0 35 -10 16 -49 17 -551 20 -411 2 -542 -1 -551 -10z' />
			<path d='M492 1108 c-16 -16 -15 -43 2 -57 9 -8 162 -10 551 -9 500 3 539 4 549 21 8 12 8 22 0 35 -10 16 -49 17 -551 20 -411 2 -542 -1 -551 -10z' />
			<path d='M492 868 c-16 -16 -15 -43 2 -57 9 -8 162 -10 551 -9 500 3 539 4 549 21 8 12 8 22 0 35 -10 16 -49 17 -551 20 -411 2 -542 -1 -551 -10z' />
			<path d='M492 628 c-16 -16 -15 -43 2 -57 9 -8 162 -10 551 -9 500 3 539 4 549 21 8 12 8 22 0 35 -10 16 -49 17 -551 20 -411 2 -542 -1 -551 -10z' />
			<path d='M492 388 c-16 -16 -15 -43 2 -57 9 -8 121 -10 391 -9 347 3 379 4 389 21 8 12 8 22 0 35 -10 16 -42 17 -390 20 -287 2 -383 -1 -392 -10z' />
			<path d='M1370 385 c-26 -32 13 -81 48 -59 22 14 27 41 12 59 -16 19 -44 19 -60 0z' />
			<path d='M2186 2544 c-34 -11 -64 -31 -102 -68 -89 -90 -84 -22 -84 -1105 l1 -946 127 -210 c111 -183 130 -210 152 -210 22 0 41 27 152 210 l127 210 1 946 c0 1083 5 1015 -84 1105 -80 79 -178 102 -290 68z m204 -98 c51 -33 80 -84 87 -151 l6 -55 -202 0 -201 0 0 33 c0 85 60 174 132 196 53 16 135 6 178 -23z m90 -326 l0 -40 -200 0 -200 0 0 40 0 40 200 0 200 0 0 -40z m-240 -880 l0 -760 -80 0 -80 0 0 760 0 760 80 0 80 0 0 -760z m240 0 l0 -760 -80 0 -80 0 0 760 0 760 80 0 80 0 0 -760z m-112 -975 c-44 -74 -84 -135 -88 -135 -4 0 -44 61 -88 135 l-81 135 169 0 169 0 -81 -135z' />
		</g>
	</svg>
);

export default SvgDailyNotes;
