import React, { lazy, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import contents from '../../routes/contentRoutes';
import useLocalStorage from '../../hooks/userLocalStorage';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
const ContentRoutes = () => {
	// const { token } = useParams();
	const location = useLocation();
	const { parseObj: user } = useLocalStorage();
	const navigate = useNavigate();
	useEffect(() => {
		if (location.pathname.includes('ResetPassword')) return;
		if (location.pathname === '/forgotpassword') return;
		if (location.pathname === '/admin') return;
		if (location.pathname === '/view-scheduler') return;
		if (!user || !user.jwtToken) navigate('/');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, navigate]);

	useEffect(() => {
		if (user && user.userType === 3) {
			// eslint-disable-next-line no-unused-expressions
			window.location.pathname === '/driver'
				? navigate('/driver')
				: navigate('/staffdashboard');
		}

		if (user && user.userType === 4) {
			// eslint-disable-next-line no-unused-expressions
			window.location.pathname === '/view-scheduler'
				? navigate('/view-scheduler')
				: navigate('/driver');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Routes>
			{contents.map((page) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Route key={page.path} {...page} />
			))}
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
