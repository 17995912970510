import * as React from 'react';

function SvgStaffHoliday(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			width='1em'
			viewBox='0 0 50 50'
			className='svg-icon'
			{...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path d='M21.5 3.5C20.1 4.8 19 6.4 19 7c0 .5-1.5 1-3.4 1C11.7 8 9 10.8 9 14.7c0 2.6 1.2 3 3 .8 1.8-2.1 2.9-1.9 2.3.5-.7 2.6.4 7 1.8 7 .5 0 2.2-1.2 3.6-2.6 3.1-3.1 4.5-1.9 4.1 3.6-.3 3.2-.7 3.6-4.7 4.9-2.7.8-6.3 3.3-9.3 6.2C6.6 38.2 3.9 40 2.4 40c-1.3 0-2.4.4-2.4 1 0 1.2 5 1.3 6.9.1.7-.5 2.5-.4 4.2.2 2 .8 3.7.8 5.7.1 1.8-.7 3.6-.7 5.5 0 1.8.7 3.7.7 5.5 0 1.8-.7 3.6-.7 5.5 0 1.8.7 3.7.7 5.5 0 1.7-.6 3.6-.7 5.3 0 1.5.5 3.5.5 4.5.1 1.6-.7 1.4-.9-1.5-1.5-1.9-.5-4.4-2.2-6.1-4.2-1.5-1.8-4.4-4.2-6.4-5.3-3-1.7-3.6-2.5-3.6-5.4 0-1.8-.9-4.9-2.1-6.7L26.8 15h4.6c2.5 0 4.6-.4 4.6-.9 0-1.8-4.1-5.9-6.5-6.5-3.1-.8-2.4-2.6 1.1-2.6 2.9 0 3.1-1.5.5-3C27.8.3 24 .9 21.5 3.5zm4 2c-3.4 3.3-3.1 4.1 1 4.1 2.1 0 4.2.7 5.3 1.8 1.6 1.8 1.5 1.8-4.5 1.2-5.6-.5-6.3-.4-6.3 1.2 0 2.2-2.7 6.2-4.2 6.2-1 0-.7-2.9.7-6.3.6-1.4.2-1.7-2.5-1.7-2 0-2.9-.4-2.4-1.1.4-.7 2.2-.9 4.5-.6 3.2.4 3.9.2 3.9-1.2C21 6.5 23.9 3 26 3c1.7 0 1.7.2-.5 2.5zM27 19.2c2.4 4 2.7 8.8.5 8.8-1 0-1.5-1-1.5-3.4 0-1.9-.4-4.6-1-6-1.3-3.6-.4-3.2 2 .6zm5.5 12.7c4.6 2.1 8 5.5 7 7-.8 1.4-5.8 1.4-7.1.1-.6-.6-2.1-.6-4.2.2-2.5 1-3.9 1-6.4 0-2.1-.8-3.6-.8-4.2-.2-1.3 1.3-6.3 1.3-7.1-.1C8.7 36.1 18.7 30 25 30c1.9 0 5.3.9 7.5 1.9zM6 43c-.8.5-2.5 1-3.7 1C1 44 0 44.4 0 45c0 1.2 5 1.3 6.9.1.7-.5 2.5-.4 4.2.2 1.9.8 3.7.8 5.6.1 1.9-.7 3.7-.7 5.6 0 1.8.7 3.6.7 5.5 0 1.8-.7 3.6-.7 5.5 0 1.8.7 3.7.7 5.5 0 1.7-.6 3.6-.7 5.3 0 1.5.5 3.5.5 4.5.1 1.6-.7 1.3-.9-1.6-1.7-4.1-1.1-5.3-1.1-9.8-.2-1.8.4-3.8.2-4.5-.5-1-.8-2.2-.7-4.5.1-2.5 1-3.9 1-6.4 0-2.3-.8-3.5-.9-4.5-.1-1.2 1.1-5.2.9-8.3-.4-.8-.4-2.2-.2-3 .3z' />
		</svg>
	);
}

export default SvgStaffHoliday;
