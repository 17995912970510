import * as React from 'react';

const SvgDog = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1.6rem'
		height='1.3rem'
		viewBox='0 0 225.000000 225.000000'
		preserveAspectRatio='xMidYMid meet'
		{...props}>
		<g transform='translate(0.000000,225.000000) scale(0.100000,-0.100000)' stroke='none'>
			<path d='M524 2232 c-124 -43 -270 -180 -332 -310 -31 -65 -36 -87 -37 -147 0 -62 4 -77 28 -113 38 -58 95 -85 167 -80 36 3 67 12 86 25 33 24 234 319 234 345 0 21 -24 40 -43 32 -8 -3 -57 -68 -108 -145 -51 -77 -106 -151 -122 -165 -42 -36 -88 -32 -134 10 -35 32 -37 37 -36 91 3 108 89 238 219 331 36 25 85 52 111 60 44 13 49 13 84 -8 21 -13 67 -47 102 -76 l64 -53 -42 -64 c-107 -159 -176 -458 -162 -700 13 -225 92 -347 263 -404 93 -32 340 -41 454 -17 259 55 355 234 322 599 -20 219 -72 391 -155 519 l-44 67 64 53 c35 29 81 63 102 76 35 21 40 21 84 8 26 -8 75 -35 111 -60 130 -93 216 -223 219 -331 1 -54 -1 -59 -36 -91 -46 -42 -92 -46 -134 -10 -16 14 -71 88 -122 165 -51 77 -100 142 -108 145 -19 8 -43 -11 -43 -32 0 -26 201 -321 234 -345 44 -31 131 -35 184 -8 154 79 126 297 -63 487 -88 89 -163 138 -241 155 -47 11 -56 10 -105 -13 -30 -14 -87 -53 -127 -87 l-73 -62 -47 31 c-62 41 -131 60 -217 60 -85 0 -153 -19 -218 -60 l-47 -30 -71 60 c-75 62 -159 110 -193 110 -12 -1 -44 -9 -72 -18z m742 -162 c187 -90 305 -376 307 -740 1 -248 -58 -353 -228 -406 -67 -21 -283 -30 -371 -15 -89 15 -185 62 -224 109 -62 75 -87 224 -70 415 31 352 150 584 332 649 73 26 186 20 254 -12z' />
			<path d='M920 1670 c-38 -38 -15 -90 40 -90 50 0 69 66 28 94 -30 21 -44 20 -68 -4z' />
			<path d='M1262 1674 c-41 -28 -22 -94 28 -94 55 0 78 52 40 90 -24 24 -38 25 -68 4z' />
			<path d='M1033 1400 c-39 -23 -53 -48 -53 -93 0 -27 8 -42 35 -66 20 -17 42 -31 50 -31 23 0 18 -15 -15 -48 -41 -41 -76 -48 -111 -23 -34 25 -55 27 -67 4 -22 -39 39 -93 105 -93 36 1 102 31 127 59 l19 21 26 -24 c67 -64 168 -71 216 -15 20 24 23 34 15 50 -14 25 -34 24 -69 -2 -34 -25 -69 -19 -111 21 -33 30 -39 50 -15 50 8 0 30 14 50 31 28 25 35 38 35 68 0 71 -53 111 -147 111 -40 0 -68 -6 -90 -20z m142 -62 c68 -31 -19 -83 -90 -54 -40 17 -45 38 -12 54 30 14 70 15 102 0z' />
			<path d='M301 1244 c-38 -85 -18 -244 47 -374 39 -79 65 -97 92 -65 11 13 8 27 -23 88 -32 65 -61 147 -54 153 2 2 18 -18 37 -44 47 -63 115 -110 138 -96 32 20 26 49 -17 78 -55 39 -108 120 -135 207 -19 64 -26 74 -47 77 -19 2 -28 -4 -38 -24z' />
			<path d='M615 878 c-59 -30 -111 -84 -137 -142 -20 -45 -23 -65 -22 -166 1 -82 8 -138 23 -194 11 -44 20 -80 19 -80 -2 -1 -20 -9 -41 -19 -49 -22 -77 -69 -77 -131 0 -43 4 -53 38 -86 23 -24 55 -43 82 -50 100 -27 215 11 208 69 -4 35 -38 39 -77 10 -58 -43 -173 -5 -179 59 -4 43 24 68 87 77 61 8 71 27 41 84 -11 22 -27 69 -34 103 -50 224 -16 348 111 404 54 24 67 46 41 72 -17 17 -37 14 -83 -10z' />
			<path d='M1552 888 c-26 -26 -13 -48 41 -72 98 -44 131 -104 130 -241 -1 -99 -22 -204 -53 -266 -30 -57 -20 -76 41 -84 63 -9 91 -34 87 -77 -4 -40 -39 -66 -101 -74 -40 -6 -53 -3 -78 15 -39 29 -73 25 -77 -10 -7 -58 108 -96 208 -69 27 7 59 26 82 50 34 33 38 43 38 86 0 62 -28 109 -77 131 -21 10 -39 18 -41 19 -1 0 8 36 19 80 15 57 22 111 23 194 1 100 -2 121 -22 165 -29 63 -74 110 -134 141 -53 27 -69 29 -86 12z' />
			<path d='M843 743 c-19 -7 -16 -38 17 -233 38 -224 38 -216 -5 -224 -94 -18 -135 -142 -73 -221 29 -37 70 -55 142 -62 71 -6 124 7 170 43 l30 22 30 -23 c49 -36 101 -48 175 -41 113 10 183 82 167 171 -9 49 -59 103 -102 111 -42 8 -42 1 -4 224 36 211 37 227 10 235 -12 4 -27 2 -34 -4 -8 -7 -27 -98 -47 -218 -30 -180 -37 -209 -57 -229 -41 -37 -26 -58 45 -66 70 -8 98 -20 114 -49 16 -30 -5 -74 -44 -91 -50 -21 -130 -17 -164 8 -51 38 -53 48 -53 350 0 245 -2 283 -16 295 -11 9 -20 10 -32 2 -15 -9 -18 -44 -22 -306 -5 -295 -5 -295 -29 -321 -60 -64 -202 -55 -231 14 -23 55 14 87 113 98 71 8 86 29 45 66 -20 19 -27 49 -57 227 -18 112 -38 210 -43 217 -11 12 -24 14 -45 5z' />
		</g>
	</svg>
);

export default SvgDog;
