import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import useLocalStorage from '../../../hooks/userLocalStorage';

const DefaultHeader = () => {
	const location = useLocation();
	const { parseObj: user } = useLocalStorage();
	if (location.pathname.includes('ResetPassword')) return;
	if (location.pathname === '/forgotpassword') return;
	if ((user?.userType === 4 || user?.userType === 3) && location.pathname === '/driver') return;
	if (location.pathname === '/admin') return;
	// eslint-disable-next-line consistent-return
	return (
		<Header>
			<div className='d-flex justify-content-end'>
				<CommonHeaderRight />
			</div>
		</Header>
	);
};

export default DefaultHeader;
