import * as React from 'react';

const SvgMemos = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1.8rem'
		height='1.8rem'
		viewBox='0 0 200.000000 200.000000'
		{...props}>
		<g transform='translate(0.000000,200.000000) scale(0.100000,-0.100000)' stroke='none'>
			<path d='M815 1696 c-31 -13 -85 -69 -85 -87 0 -5 -57 -9 -130 -9 -118 0 -130 -2 -140 -19 -6 -13 -10 -231 -10 -640 0 -550 2 -621 16 -635 13 -14 59 -16 348 -16 l332 0 202 203 202 203 0 436 c0 384 -2 438 -16 452 -13 14 -51 16 -259 16 l-243 0 -20 34 c-38 64 -128 92 -197 62z m88 -87 c28 -15 37 -28 37 -56 0 -30 -34 -63 -63 -63 -54 0 -80 78 -37 112 26 21 34 22 63 7z m-163 -130 c7 -17 30 -42 52 -56 34 -23 45 -25 102 -21 l64 6 51 -54 c50 -54 76 -65 99 -42 25 25 13 61 -39 114 -82 83 -79 85 173 82 l213 -3 0 -370 0 -370 -174 -3 c-108 -1 -180 -7 -188 -13 -10 -9 -13 -53 -13 -185 l0 -174 -270 0 -270 0 0 553 c0 305 3 557 7 560 3 4 46 7 93 7 l88 0 12 -31z m640 -812 c0 -1 -47 -49 -105 -107 l-105 -105 0 108 0 107 105 0 c58 0 105 -1 105 -3z' />
			<path d='M642 1128 c-15 -15 -15 -51 0 -66 9 -9 103 -12 360 -12 l348 0 11 24 c8 17 8 29 0 45 -12 21 -15 21 -359 21 -257 0 -351 -3 -360 -12z' />
			<path d='M640 934 c-10 -11 -11 -23 -4 -42 l9 -27 335 -3 c184 -1 345 0 357 3 27 6 41 49 23 70 -19 23 -701 22 -720 -1z' />
			<path d='M642 748 c-15 -15 -15 -51 0 -66 8 -8 60 -12 169 -12 130 0 160 3 173 16 19 18 21 45 4 62 -17 17 -329 17 -346 0z' />
			<path d='M642 558 c-17 -17 -15 -44 4 -62 12 -12 37 -16 105 -16 50 0 99 5 110 10 21 12 25 50 7 68 -16 16 -210 16 -226 0z' />
		</g>
	</svg>
);

export default SvgMemos;
